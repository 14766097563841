import React, { useState, useEffect, Component } from 'react';
import logo from './logo.svg';
import './App.css';
import protobuf from "protobufjs";
import './App.css';
import firebase from "firebase/app";

import { useAuthState } from 'react-firebase-hooks/auth';

function App() {
  let pb = protobuf.parse(`
  syntax = "proto3";
  package ih;
  
  message Splunk {
      int64 ts = 1;
      sint32 diff = 2;
      string id = 3;
      int32 num = 4;
  }
  
  message Batch {
      repeated Splunk splunks = 1;
  }
  
  message SingalMessage {
      string id = 1;
      int64 ts = 2;
      string type = 3;
      string to = 4;
      string from = 5;
      string message = 6;
  }
  
  message SingalMessageBatch {
      repeated SingalMessage messages = 1;
  }
  `);
  // const { loginWithRedirect, user, isAuthenticated, isLoading, logout, getAccessTokenSilently } = useAuth0();
  const [user, loading, _error] = useAuthState(firebase.auth());
  const getAccessToken = async () => { return await firebase.auth().currentUser.getIdToken(false); };
  const createNewUser = async () => {
    let at = await getAccessToken();
    await fetch("https://api.lappa.appscifi.com/me", {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${at}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify({
        name: "Orvar"
      })
    });
  }
  const getMe = async () => {
    let at = await getAccessToken();
    await fetch("https://api.lappa.appscifi.com/me", {
      headers: {
        Authorization: `Bearer ${at}`,
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
  }

  const [to, setTo] = React.useState();
  const [message, setMessage] = React.useState();
  const [helloes, setHelloes] = React.useState([]);
  const [newHelloes, setNewHelloes] = React.useState([]);
  const [splunks, setSplunks] = React.useState({});
  const [newSplunks, setNewSplunks] = React.useState([]);

  useEffect(() => {
    var Batch = pb.root.lookupType("ih.Batch");

    let ws = new WebSocket('wss://api.lappa.appscifi.com/ws');
    ws.onopen = () => {
      console.log('connected')
    };

    ws.onmessage = async evt => {
      let buffer = await evt.data.arrayBuffer();
      var content = new Uint8Array(buffer, 0, buffer.length);
      let batch = Batch.decode(content);

      console.log(batch);

      setNewSplunks(batch.splunks);
    }
    ws.onclose = () => {
      console.log('disconnected')
    }
  }
    , []);

  useEffect(async () => {
    if (!user)
      return;

    var SingalMessage = pb.root.lookupType("ih.SingalMessage");
    let at = await getAccessToken();
    let ws = new WebSocket(`wss://api.lappa.appscifi.com/signal`);
    ws.onopen = () => {
      console.log('connected')
      ws.send(at)
    };

    ws.onmessage = async evt => {
      let buffer = await evt.data.arrayBuffer();
      var content = new Uint8Array(buffer, 0, buffer.length);
      let msg = SingalMessage.decode(content);

      console.log(msg);

      setNewHelloes([msg]);
    }
    ws.onclose = () => {
      console.log('disconnected')
    }
  }
    , [user]);

  useEffect(() => {
    let m = { ...splunks };
    for (let o of newSplunks) {
      m[o.id] = o;
    }
    setSplunks(m);
  },
    [newSplunks])
  useEffect(() => {

    setHelloes(helloes.concat(newHelloes));
  },
    [newHelloes])


  let new_message = async () => {
    const type = "offer";
    let at = await getAccessToken();
    await fetch(`//api.lappa.appscifi.com/signal/${to}/${type}`, {
      method: 'POST',
      body: message,
      headers: {
        Authorization: `Bearer ${at}`
      }
    });
  };
  let post = async () => {
    let at = await getAccessToken();
    await fetch("https://api.lappa.appscifi.com/splunks", {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${at}`
      }
    });
  };
  let inc = async id => {
    let at = await getAccessToken();
    await fetch(`https://api.lappa.appscifi.com/splunks/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${at}`
      }
    });
  };
  let del = async id => {
    let at = await getAccessToken();
    await fetch(`https://api.lappa.appscifi.com/splunks/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${at}`
      }
    });
  };

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await firebase.auth().currentUser.getIdToken(true);
        console.log(accessToken);

      } catch (e) {
        console.log(e.message);
      }
    };

    if (user)
      getUserMetadata();
  }, [user]);

  return (
    <div className="App">

      {user ? (
        <div>
          <div>
            <img src={user.picture} alt={user.name} />
            <h2>{user.name}</h2>
            <p>{user.email}</p>
            <button onClick={getMe}>
              getMe
            </button>
            <button onClick={createNewUser}>
              createNewUser
            </button>
            <br />
            <button onClick={() => firebase.auth().signOut()}>
              Log Out
          </button>
          </div>

        </div>
      ) : (!loading && <button onClick={() => firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider())}>Log In</button>)}

      {user &&
        <div>
          <ul>
            {Object.values(splunks).filter(s => s.diff > 0).map(s => <li key={s.id}>{s.id}:{s.num} <button onClick={() => inc(s.id)}>inc</button><button onClick={() => del(s.id)}>del</button> </li>)}
          </ul>
          <button onClick={post}>new</button>
          <ul>
            {helloes.map((o, i) => <li key={i}>{o.from}-{o.to} : {o.message} </li>)}
          </ul>
          <input type="text" value={to} onChange={event => setTo(event.target.value)} />
          <br />
          <input type="text" value={message} onChange={event => setMessage(event.target.value)} />
          <button onClick={new_message}>new</button>
        </div>
      }
    </div>
  );
}

export default App;
